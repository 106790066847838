import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import SidebarRow from "./SidebarRow";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/dataSlice";
import { AdminMenu, GuestMenu, StudentMenu, TutorMenu } from "../data/menu";

function SideBar() {

  const currentUser = useSelector(selectUser);
  const [open, setOpen] = useState(true);

  return (
    <div
      className={` ${open ? "w-44 md:w-60 lg:w-64 xl:w-72 2xl:w-[440px]" : "w-16"
        } p-2 relative bg-[#1B1340] text-gray-100 rounded-sm h-full duration-300
        lg:opacity-100 md:px-4`}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        className={`absolute cursor-pointer rounded-full  h-7 w-7 top-9 -right-3 z-50 bg-gray-400 flex 
      items-center justify-center ${open && "rotate-180"} lg:hidden`}
      >
        <ChevronRightIcon className=" h-4 text-white" />
      </div>

      <div
        className={`${!open && "hidden"
          } flex  space-x-3 items-center justify-start sticky top-0 bg-[#1B1340]`}
      >
        <div className="pl-2 md:pl-4 mt-5">
          <img
            src="/images/prechs.png"
            alt="school-badge"
            className="w-8 h-8 md:w-20 md:h-20"
          />
        </div>
        <p className="font-bold text-white md:text-xl">
          PR<span className="text-[#9153FF]">ECH</span>OHUB
        </p>
      </div>

      <div className="h-screen overflow-y-auto pb-20 md:pb-32 scrollbar-hide">
        {currentUser.userType === "Student" && (
          <>
            {StudentMenu?.map(({ _id, Icon, title, path }) => (
              <SidebarRow
                key={_id}
                Icon={Icon}
                title={title}
                open={open}
                path={path}
              />
            ))}
          </>
        )}

        {currentUser.userType === "Tutor" && (
          <>
            {TutorMenu?.map(({ _id, Icon, title, path }) => (
              <SidebarRow
                key={_id}
                Icon={Icon}
                title={title}
                open={open}
                path={path}
              />
            ))}
          </>
        )}

        {currentUser.userType === "Admin" && (
          <>
            {AdminMenu?.map(({ _id, Icon, title, path }) => (
              <SidebarRow
                key={_id}
                Icon={Icon}
                title={title}
                open={open}
                path={path}
              />
            ))}
          </>
        )}

        {currentUser.userType === "Guest" && (
          <>
            {GuestMenu?.map(({ _id, Icon, title, path }) => (
              <SidebarRow
                key={_id}
                Icon={Icon}
                title={title}
                open={open}
                path={path}
              />
            ))}
          </>
        )}

      </div>
    </div>
  );
}

export default SideBar;

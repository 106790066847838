import React from "react";
import { MenuIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { CiBrightnessUp } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { selectUser } from "../slices/dataSlice";

function Header({ openSideBar, showDropDown, sidebar }) {
  const user = useSelector(selectUser);

  return (
    <header>
      <div className="relative top-0  flex space-x-3 justify-between  bg-[#07142b] py-2 px-3">
        {/* Left */}
        <div className="flex items-center justify-center space-x-3 lg:hidden">
          <div className="">
            <img
              src="/images/prechs.png"
              alt="school-badge"
              className="h-8 w-8 md:h-10"
            />
          </div>
          <p className="font-bold text-white">
            PR<span className="text-[#9153FF]">ECHO</span>HUB
          </p>
        </div>

        <div className="hidden lg:inline-flex" />

        {/* Right */}
        <div className="flex space-x-4 items-center justify-around text-gray-100">
          <div className="flex flex-col items-center">
            <p className="hidden md:inline-flex cursor-pointer text-sm">
              Learning is a treasure{" "}
            </p>
            <p className="hidden md:inline-flex cursor-pointer text-sm">
              Knowledge is power.{" "}
            </p>
          </div>

          <CiBrightnessUp className="hidden md:inline-flex h-8 w-8 animate-bounce transition-all duration-300  cursor-pointer" />

          <div className="link flex flex-col">
            <p className=" text-xs md:text-sm text-center "> Hello ,👏 </p>
            <p className=" text-xs md:text-sm text-center">
              {" "}
              {user.name ? user?.name.split(" ")[0] : ""}{" "}
            </p>
          </div>

          <div
            onClick={showDropDown}
            className="w-10 h-10 md:w-12 md:h-12  rounded-full cursor-pointer"
          >
            <img
              src={
                user?.imageUrl ||
                `https://ui-avatars.com/api/name=${user?.name}&background=random`
              }
              className="w-full h-full rounded-full border-2  hover:scale-105 object-cover"
              alt="user-profile"
            />
          </div>
        </div>

        <div className="flex items-center justify-center lg:hidden">
          <div
            onClick={openSideBar}
            className=" flex items-center justify-center p-1 w-8 h-8 rounded-full border-2 border-gray-200 hover:border-white active:border-white cursor-pointer"
          >
            {sidebar ? (
              <MenuIcon className="h-6 text-gray-200 hover:text-white active:text-white  " />
            ) : (
              <IoMdClose className="h-6 text-gray-200 hover:text-white active:text-white  " />
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
